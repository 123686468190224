import { PATIENT_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  getpatientListLoading: false,
  getpatientListSuccess: false,
  getpatientListFailure: false,
  getpatientListError: null,
  patientList: [],
  patientListCount: 0,

  getOtppatientListLoading: false,
  getOtppatientListSuccess: false,
  getOtppatientListFailure: false,
  getOtppatientListError: null,
  otpPatientList: [],
  otpPatientListCount: 0,

  isReminderEnabledLoading: false,
  isReminderEnabledSuccess: false,
  isReminderEnabledFailure: false,
  isReminderEnabledError: null,
  isReminderEnabledData: [],

  getMotherWithAncDataLoading: false,
  getMotherWithAncDataSuccess: false,
  getMotherWithAncDataFailure: false,
  getMotherWithAncDataError: null,
  motherWithAncDataList: [],
  motherWithAncDataCount: 0,

  getSinglePatientDetailLoading: false,
  getSinglePatientDetailSuccess: false,
  getSinglePatientDetailFailure: false,
  getSinglePatientDetailError: null,
  singlePatientDetail: null,

  getOtpPatientDetailLoading: false,
  getOtpPatientDetailSuccess: false,
  getOtpPatientDetailFailure: false,
  getOtpPatientDetailError: null,
  getOtpPatientDetail: null,

  getOtpVisitListLoading: false,
  getOtpVisitListSuccess: false,
  getOtpVisitListFailure: false,
  getOtpVisitListError: null,
  getOtpVisitList: [],
  getOtpVisitListCount: 0,

  getPatientChildListLoading: false,
  getPatientChildListSuccess: false,
  getPatientChildListFailure: false,
  getPatientChildListError: null,
  patientChildList: [],

  changePatientGenderLoading: false,
  changePatientGenderSuccess: false,
  changePatientGenderFailure: false,
  changePatientGenderError: null,
  patientGender: [],
};

export const patientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PATIENT_REQUEST.GET_PATIENT_LIST_REQUEST:
      return {
        ...state,
        getpatientListLoading: true,
        getpatientListSuccess: false,
        getpatientListFailure: false,
        getpatientListError: null,
        patientList: [],
        patientListCount: 0,
      };
    case PATIENT_REQUEST.GET_PATIENT_LIST_FAILURE:
      return {
        ...state,
        getpatientListLoading: false,
        getpatientListSuccess: false,
        getpatientListFailure: true,
        getpatientListError: action.payload,
      };
    case PATIENT_REQUEST.GET_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        getpatientListLoading: false,
        getpatientListSuccess: true,
        getpatientListFailure: false,
        getpatientListError: null,
        patientList: action.payload,
        patientListCount: action.info.count,
      };
    case PATIENT_REQUEST.GET_OTP_PATIENT_LIST_REQUEST:
      return {
        ...state,
        getOtppatientListLoading: true,
        getOtppatientListSuccess: false,
        getOtppatientListFailure: false,
        getOtppatientListError: null,
        otpPatientList: [],
        otpPatientListCount: 0,
      };
    case PATIENT_REQUEST.GET_OTP_PATIENT_LIST_FAILURE:
      return {
        ...state,
        getOtppatientListLoading: false,
        getOtppatientListSuccess: false,
        getOtppatientListFailure: true,
        getOtppatientListError: action.payload,
      };
    case PATIENT_REQUEST.GET_OTP_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        getOtppatientListLoading: false,
        getOtppatientListSuccess: true,
        getOtppatientListFailure: false,
        getOtppatientListError: null,
        otpPatientList: action.payload,
        otpPatientListCount: action.info.count,
      };
    case PATIENT_REQUEST.IS_REMINDER_ENABLED_REQUEST:
      return {
        ...state,
        isReminderEnabledLoading: true,
        isReminderEnabledSuccess: false,
        isReminderEnabledFailure: false,
        isReminderEnabledError: null,
        isReminderEnabledData: [],
      };
    case PATIENT_REQUEST.IS_REMINDER_ENABLED_FAILURE:
      return {
        ...state,
        isReminderEnabledLoading: false,
        isReminderEnabledSuccess: false,
        isReminderEnabledFailure: true,
        isReminderEnabledError: action.payload,
      };
    case PATIENT_REQUEST.IS_REMINDER_ENABLED_SUCCESS:
      return {
        ...state,
        isReminderEnabledLoading: false,
        isReminderEnabledSuccess: true,
        isReminderEnabledFailure: false,
        isReminderEnabledError: null,
        isReminderEnabledData: action.payload,
      };
    case PATIENT_REQUEST.GET_MOTHERS_WITH_ANC_DATA_REQUEST:
      return {
        ...state,
        getMotherWithAncDataLoading: true,
        getMotherWithAncDataSuccess: false,
        getMotherWithAncDataFailure: false,
        getMotherWithAncDataError: null,
        motherWithAncDataList: [],
        motherWithAncDataCount: 0,
      };
    case PATIENT_REQUEST.GET_MOTHERS_WITH_ANC_DATA_FAILURE:
      return {
        ...state,
        getMotherWithAncDataLoading: false,
        getMotherWithAncDataSuccess: false,
        getMotherWithAncDataFailure: true,
        getMotherWithAncDataError: action.payload,
      };
    case PATIENT_REQUEST.GET_MOTHERS_WITH_ANC_DATA_SUCCESS:
      return {
        ...state,
        getMotherWithAncDataLoading: false,
        getMotherWithAncDataSuccess: true,
        getMotherWithAncDataFailure: false,
        getMotherWithAncDataError: null,
        motherWithAncDataList: action.payload,
        motherWithAncDataCount: action.info.count,
      };
    case PATIENT_REQUEST.GET_SINGLE_PATIENT_DETAIL_REQUEST:
      return {
        ...state,
        getSinglePatientDetailLoading: true,
        getSinglePatientDetailSuccess: false,
        getSinglePatientDetailFailure: false,
        getSinglePatientDetailError: null,
        singlePatientDetail: null,
      };
    case PATIENT_REQUEST.GET_SINGLE_PATIENT_DETAIL_FAILURE:
      return {
        ...state,
        getSinglePatientDetailLoading: false,
        getSinglePatientDetailSuccess: false,
        getSinglePatientDetailFailure: true,
        getSinglePatientDetailError: action.payload,
      };
    case PATIENT_REQUEST.GET_SINGLE_PATIENT_DETAIL_SUCCESS:
      return {
        ...state,
        getSinglePatientDetailLoading: false,
        getSinglePatientDetailSuccess: true,
        getSinglePatientDetailFailure: false,
        getSinglePatientDetailError: null,
        singlePatientDetail: action.payload,
      };
    case PATIENT_REQUEST.GET_OTP_PATIENT_DETAIL_REQUEST:
      return {
        ...state,
        getOtpPatientDetailLoading: true,
        getOtpPatientDetailSuccess: false,
        getOtpPatientDetailFailure: false,
        getOtpPatientDetailError: null,
        getOtpPatientDetail: null,
      };
    case PATIENT_REQUEST.GET_OTP_PATIENT_DETAIL_FAILURE:
      return {
        ...state,
        getOtpPatientDetailLoading: false,
        getOtpPatientDetailSuccess: false,
        getOtpPatientDetailFailure: true,
        getOtpPatientDetailError: action.payload,
      };
    case PATIENT_REQUEST.GET_OTP_PATIENT_DETAIL_SUCCESS:
      return {
        ...state,
        getOtpPatientDetailLoading: false,
        getOtpPatientDetailSuccess: true,
        getOtpPatientDetailFailure: false,
        getOtpPatientDetailError: null,
        getOtpPatientDetail: action.payload,
      };
    case PATIENT_REQUEST.GET_OTP_VISIT_LIST_REQUEST:
      return {
        ...state,
        getOtpVisitListLoading: true,
        getOtpVisitListSuccess: false,
        getOtpVisitListFailure: false,
        getOtpVisitListError: null,
        getOtpVisitList: null,
      };
    case PATIENT_REQUEST.GET_OTP_VISIT_LIST_FAILURE:
      return {
        ...state,
        getOtpVisitListLoading: false,
        getOtpVisitListSuccess: false,
        getOtpVisitListFailure: true,
        getOtpVisitListError: action.payload,
      };
    case PATIENT_REQUEST.GET_OTP_VISIT_LIST_SUCCESS:
      return {
        ...state,
        getOtpVisitListLoading: false,
        getOtpVisitListSuccess: true,
        getOtpVisitListFailure: false,
        getOtpVisitListError: null,
        getOtpVisitList: action.payload,
        getOtpVisitListCount: action.info.count,
      };
    case PATIENT_REQUEST.GET_PATIENT_CHILDS_LIST_REQUEST:
      return {
        ...state,
        getPatientChildListLoading: true,
        getPatientChildListSuccess: false,
        getPatientChildListFailure: false,
        getPatientChildListError: null,
      };
    case PATIENT_REQUEST.GET_PATIENT_CHILDS_LIST_FAILURE:
      return {
        ...state,
        getPatientChildListLoading: false,
        getPatientChildListSuccess: false,
        getPatientChildListFailure: true,
        getPatientChildListError: action.payload,
      };
    case PATIENT_REQUEST.GET_PATIENT_CHILDS_LIST_SUCCESS:
      return {
        ...state,
        getPatientChildListLoading: false,
        getPatientChildListSuccess: true,
        getPatientChildListFailure: false,
        getPatientChildListError: null,
        patientChildList: action.payload,
      };
      case PATIENT_REQUEST.CHANGE_PATIENT_GENDER_REQUEST:
        return {
          ...state,
          changePatientGenderLoading: true,
          changePatientGenderSuccess: false,
          changePatientGenderFailure: false,
          changePatientGenderError: null,
        };
      case PATIENT_REQUEST.CHANGE_PATIENT_GENDER_FAILURE:
        return {
          ...state,
          changePatientGenderLoading: false,
          changePatientGenderSuccess: false,
          changePatientGenderFailure: true,
          changePatientGenderError: action.payload,
        };
      case PATIENT_REQUEST.CHANGE_PATIENT_GENDER_SUCCESS:
        return {
          ...state,
          changePatientGenderLoading: false,
          changePatientGenderSuccess: true,
          changePatientGenderFailure: false,
          changePatientGenderError: null,
          patientGender: action.payload,
        };
  
    default:
      return state;
  }
};
