import React, { useMemo, useState, useEffect } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { AnimateLoading } from "../../../_partials/controls";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import { PATH } from "../../../../app/config";
import { reactSelectCustom, dashboardOption } from "../../../../app/constant";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal } from "react-bootstrap";
import { logout } from "../../../../app/modules/Auth/_redux/authCrud";
import {
  getAllPoliciesAction,
  getPolicesByRoleIdAction,
  getPolicesByRoleIdSidebarAction,
} from "../../../../redux/actions";

export function IsNamePermissionAllowed() {
  return IsPermissionAllowed("pt:patientName")
}

export function IsMrnPermissionAllowed() {
  return IsPermissionAllowed("pt:patientMRN")
}


export function IsPermissionAllowed(permission) {
  let policesData = useSelector((state) => state?.clinicsReducer);
  return (
    policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.filter(
      (items) => items?.value === permission
    ).length > 0
  );
  //return isPermissionAllowed;
}





export function Header() {
  const { user } = useSelector((state) => state.auth);
  let policesData = useSelector((state) => state?.clinicsReducer);
  const isPermissionAllowed = (value) => {
    return (
      policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.filter(
        (items) => items?.value === value
      ).length > 0
    );
  };
  useEffect(() => {
    dispatch(getPolicesByRoleIdAction(user?.data?.policyGroupId));
  }, []);
  useEffect(() => {
    dispatch(getAllPoliciesAction());
  }, []);
  const isSectionPermissionAllowed = (value) => {
    return (
      policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.filter(
        (items) => items?.value.startsWith(value)
      ).length > 0
    );
  };
  const [dashboard, setDashboard] = useState({});
  
  let roleId = user?.data?.roleIds?.[0];
  let dispatch = useDispatch();
  useEffect(() => {
    if (user?.data?.policyGroupId)
      dispatch(getPolicesByRoleIdSidebarAction(user?.data?.policyGroupId));
  }, []);

  const uiService = useHtmlClassService();
  const history = useHistory();
  const location = useLocation();



  const handleOnChange = ({ value }) => {
    if (value) {
      if (value === 1) {
        return history.push(PATH.MAIN_DASHBOARD);
      }
      if (value === 2) {
        return history.push(PATH.IMMUNIZATION_DASHBOARD);
      }
      if (value === 3) {
        return history.push(PATH.ANC_DASHBOARD);
      }
      if (value === 4) {
        return history.push(PATH.OPD_FIVE_REGISTER_DASHBOARD);
      }
      if (value === 5) {
        return history.push(PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD);
      }
      if (value === 6) {
        return history.push(PATH.PRIMARY_HEALTH_UNIT_DASHBOARD);
      }
      if (value === 7) {
        return history.push(PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD);
      }
      if (value === 8) {
        return history.push(PATH.POSTNATAL_CARE_DASHBOARD);
      }
      if (value === 9) {
        return history.push(PATH.LABOUR_DELIVERY_MATERNITY_DASHBOARD);
      }
      if (value === 10) {
        return history.push(PATH.WASH_REGISTER_DASHBOARD);
      }
      if (value === 11) {
        return history.push(PATH.PROTECTION_MONTHLY_REPORTING_DASHBOARD);
      }
      if (value === 12) {
        return history.push(PATH.EMERGENCY_PREPARENESS_MONTHLY_REPORTING_DASHBOARD);
      }
      if (value === 13) {
        return history.push(PATH.OTP_DASHBOARD);
      }
      if (value === 14) {
        return history.push(PATH.SC_DASHBOARD);
      }
      // if (value === 15) {
      //   return history.push(PATH.SUPPLY_MANAGEMENT_DASHBOARD);
      // }
    
    }
  };

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      ),
    };
  }, [uiService]);

  useEffect(() => {
    handleDashboard(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const manueOptions = [
    { value: 1, label: "Global Dashboard" },
    { value: 2, label: "Immunization" },
    { value: 3, label: "Antenatal Care" },
    { value: 4, label: "OPD Over Five" },
    { value: 5, label: "OPD Under Five" },
    { value: 6, label: "Primary Health Unit" },
    { value: 7, label: "Hospital InPatient Register" },
    { value: 8, label: "Posta Natal Care" },
    { value: 9, label: "Labour Delivery Maternity" },
    { value: 10, label: "Wash Register" },
    { value: 11, label: "Protection Monthly Reporting" },
    { value: 12, label: "Emergency Preparedness Monthly Reporting" },
    { value: 13, label: "OTP Dashboard" },
    { value: 14, label: "SC Dashboard" },
    // { value: 15, label: "Supply Management" },
  ];
  
  // Modify the options based on viewState and viewState2
  let modifiedOptions = [];
  if (isSectionPermissionAllowed("md:globalDashboard"))
    modifiedOptions = [...modifiedOptions, manueOptions[0]];
  if (isSectionPermissionAllowed("md:immunization"))
    modifiedOptions = [...modifiedOptions, manueOptions[1]];
  if (isSectionPermissionAllowed("md:antenatalCare"))
    modifiedOptions = [...modifiedOptions, manueOptions[2]];
  if (isSectionPermissionAllowed("md:overFive"))
    modifiedOptions = [...modifiedOptions, manueOptions[3]];
  if (isSectionPermissionAllowed("md:underFive"))
    modifiedOptions = [...modifiedOptions, manueOptions[4]];
  if (isSectionPermissionAllowed("md:primaryHealthUnit"))
    modifiedOptions = [...modifiedOptions, manueOptions[5]];
  if (isSectionPermissionAllowed("md:inPatientRegister"))
    modifiedOptions = [...modifiedOptions, manueOptions[6]];
  if (isSectionPermissionAllowed("md:postaNatalCare"))
    modifiedOptions = [...modifiedOptions, manueOptions[7]];
  if (isSectionPermissionAllowed("md:labourDeliveryMaternity"))
    modifiedOptions = [...modifiedOptions, manueOptions[8]];
  if (isSectionPermissionAllowed("md:washRegister"))
    modifiedOptions = [...modifiedOptions, manueOptions[9]];
  if (isSectionPermissionAllowed("md:protectionMonthlyReporting"))
    modifiedOptions = [...modifiedOptions, manueOptions[10]];
  if (isSectionPermissionAllowed("md:emergencyPreparednessMonthlyReporting"))
    modifiedOptions = [...modifiedOptions, manueOptions[11]];
  if (isSectionPermissionAllowed("md:otp"))
    modifiedOptions = [...modifiedOptions, manueOptions[12]];
  if (isSectionPermissionAllowed("md:sc"))
    modifiedOptions = [...modifiedOptions, manueOptions[13]];
  // if (isSectionPermissionAllowed("md:otp"))
  //   modifiedOptions = [...modifiedOptions, manueOptions[14]];

  const handleDashboard = (current) => {
    let option = [...modifiedOptions];
    ;
    if (current === PATH.IMMUNIZATION_DASHBOARD) {
      if (option.length > 2) return setDashboard(option[1]);
      else return setDashboard(option[0]);
    }
    if (current === PATH.ANC_DASHBOARD) {
      return setDashboard(option[option.length - 13]);
    }
    if (current === PATH.OPD_FIVE_REGISTER_DASHBOARD) {
      return setDashboard(option[option.length - 12]);
    }
    if (current === PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD) {
      return setDashboard(option[option.length - 11]);
    }
    if (current === PATH.PRIMARY_HEALTH_UNIT_DASHBOARD) {
      return setDashboard(option[option.length - 10]);
    }
    if (current === PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD) {
      return setDashboard(option[option.length - 9]);
    }
    if (current === PATH.POSTNATAL_CARE_DASHBOARD) {
      return setDashboard(option[option.length - 8]);
    }
    if (current === PATH.LABOUR_DELIVERY_MATERNITY_DASHBOARD) {
      return setDashboard(option[option.length - 7]);
    }
    if (current === PATH.WASH_REGISTER_DASHBOARD) {
      return setDashboard(option[option.length - 6]);
    }
    if (current === PATH.PROTECTION_MONTHLY_REPORTING_DASHBOARD) {
      return setDashboard(option[option.length - 5]);
    }
    if (current === PATH.EMERGENCY_PREPARENESS_MONTHLY_REPORTING_DASHBOARD) {
      return setDashboard(option[option.length - 4]);
    }
    if (current === PATH.OTP_DASHBOARD) {
      return setDashboard(option[option.length - 3]);
    }
    if (current === PATH.SC_DASHBOARD) {
      return setDashboard(option[option.length - 2]);
    }
    // if (current === PATH.SUPPLY_MANAGEMENT_DASHBOARD) {
    //   return setDashboard(option[option.length - 1]);
    // }
    
    return setDashboard(option[0]);
  };

  const [selectedState, setselectedState] = useState(null);
  useEffect(() => {
    localStorage.setItem("StateId", selectedState?.value);
  }, [selectedState]);
  const changeState = (value) => {
    setselectedState({ label: value?.label, value: value?.value });
    ;
  };
  useEffect(() => {
    let option = [...modifiedOptions];
    ;
    if (location.pathname === PATH.MAIN_DASHBOARD) {
      return setDashboard({ value: 1, label: "Global Dashboard" });
    }
    if (location.pathname === PATH.IMMUNIZATION_DASHBOARD) {
      return setDashboard({ value: 2, label: "Immunization" });
    }
    if (location.pathname === PATH.ANC_DASHBOARD) {
      return setDashboard({ value: 3, label: "Antenatal Care" });
    }
    if (location.pathname === PATH.OPD_FIVE_REGISTER_DASHBOARD) {
      return setDashboard({ value: 4, label: "OPD Over Five" });
    }
    if (location.pathname === PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD) {
      return setDashboard({ value: 5, label: "OPD Under Five" });
    }
    if (location.pathname === PATH.PRIMARY_HEALTH_UNIT_DASHBOARD) {
      return setDashboard({ value: 6, label: "Primary Health Unit" });
    }
    if (location.pathname === PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD) {
      return setDashboard({ value: 7, label: "Hospital InPatient Register" });
    }
    if (location.pathname === PATH.POSTNATAL_CARE_DASHBOARD) {
      return setDashboard({ value: 8, label: "Posta Natal Care" });
    }
    if (location.pathname === PATH.LABOUR_DELIVERY_MATERNITY_DASHBOARD) {
      return setDashboard({ value: 9, label: "Labour Delivery Maternity" });
    }
    if (location.pathname === PATH.WASH_REGISTER_DASHBOARD) {
      return setDashboard({ value: 10, label: "Wash Register" });
    }
    if (location.pathname === PATH.PROTECTION_MONTHLY_REPORTING_DASHBOARD) {
      return setDashboard({ value: 11, label: "Protection Monthly Reporting" });
    }
    if (location.pathname === PATH.EMERGENCY_PREPARENESS_MONTHLY_REPORTING_DASHBOARD) {
      return setDashboard({ value: 12, label: "Emergency Preparedness Monthly Reporting" });
    }
    if (location.pathname === PATH.OTP_DASHBOARD) {
      return setDashboard({ value: 13, label: "OTP Dashboard" });
    }
    if (location.pathname === PATH.SC_DASHBOARD) {
      return setDashboard({ value: 14, label: "SC Dashboard" });
    }
    if (location.pathname === PATH.SUPPLY_MANAGEMENT_DASHBOARD) {
      return setDashboard({ value: 15, label: "Supply Management" });
    }

    return setDashboard(option[0]);
  }, [location])
  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}
        >
          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          <>
            {(location.pathname === PATH.ANC_DASHBOARD ||
              location.pathname === PATH.IMMUNIZATION_DASHBOARD ||
              location.pathname === PATH.OPD_FIVE_REGISTER_DASHBOARD ||
              location.pathname === PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD ||
              location.pathname === PATH.PRIMARY_HEALTH_UNIT_DASHBOARD ||
              location.pathname === PATH.SC_DASHBOARD ||
              location.pathname === PATH.SUPPLY_MANAGEMENT_DASHBOARD ||
              location.pathname === PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD ||
              location.pathname === PATH.OTP_DASHBOARD ||
              location.pathname === PATH.POSTNATAL_CARE_DASHBOARD ||
              location.pathname === PATH.LABOUR_DELIVERY_MATERNITY_DASHBOARD ||
              location.pathname === PATH.WASH_REGISTER_DASHBOARD ||
              location.pathname === PATH.PROTECTION_MONTHLY_REPORTING_DASHBOARD ||
              location.pathname === PATH.EMERGENCY_PREPARENESS_MONTHLY_REPORTING_DASHBOARD ||
              location.pathname === PATH.MAIN_DASHBOARD) && (
                <div
                  className="header-menu-wrapper header-menu-wrapper-left"
                  id="kt_header_menu_wrapper"
                >
                  <div
                    id="kt_header_menu"
                    className="header-menu header-menu-mobile align-items-center"
                  >
                    <Select
                      options={modifiedOptions}
                      styles={reactSelectCustom}
                      className="w230"
                      onChange={(value) => handleOnChange(value)}
                      value={dashboard}
                    />
                    {/* {user && user.data && user.data.roles[0] === "SuperAdmin" && (
                    <Select
                      options={(locationReducer?.states || []).map((item) => {
                        return {
                          value: item.id,
                          label: item.locationName,
                        };
                      })}
                      className="d-inline-block w-75 ml-2"
                      placeholder="Select state"
                      onChange={(value) => changeState(value)}
                      value={selectedState}
                      // isClearable
                      // isLoading={clinics_reducer.getClinicListLoading}
                      // isDisabled={!!clinics_reducer.getClinicListLoading}
                    />
                  )} */}
                  </div>
                </div>
              )}
          </>

          {!layoutProps.menuHeaderDisplay && <div />}

          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
      <SessionExpireModal />
    </>
  );
}

function SessionExpireModal() {
  let history = useHistory();
  const reLogin = () => {
    window.localStorage.removeItem("tokenExpiry");
    logout()
      .then((resp) => resp.data)
      .then((data) => { })
      .catch((response) => { })
      .finally(() => {
        history.push("/logout");
      });
  };
  const genericReducer = useSelector((state) => state.genericReducer);
  return (
    <Modal size="sm" centered show={genericReducer.sessionExpire}>
      <Modal.Body closeButton className="text-center mt-3">
        <h4>
          Session Expired
          <br />
          <small className="mt-2">
            Your current session has been expired, please login again to
            conitnue
          </small>
        </h4>
        <Button
          size="sm"
          className="mt-3"
          variant="primary"
          onClick={() => reLogin()}
        >
          Re-Login
        </Button>
      </Modal.Body>
    </Modal>
  );
}
