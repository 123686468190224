// export function request(actionType) {
//   const tokenExpiryLocalStorage = window.localStorage.getItem("tokenExpiry");
//   const checkTokenExpiry =
//     new Date(tokenExpiryLocalStorage).getTime() > new Date().getTime();
//   if (checkTokenExpiry === false) {
//     return {
//       type: GENERIC.SET_SESSION_EXPIRE_STATE,
//       payload: true,
//     };
//   } else {
//     return {
//       type: actionType,
//       isLoading: true,
//     };
//   }
// }

// export function success(actionType, response, info) {
//   return {
//     type: actionType,
//     isSuccess: true,
//     payload: response,
//     info: info,
//   };
// }

// export function failure(actionType, error) {
//   return {
//     type: actionType,
//     isSuccess: false,
//     payload: error,
//     info: error,
//   };
// }

export function request(actionType, key) {
  const tokenExpiryLocalStorage = window.localStorage.getItem("tokenExpiry");
  const checkTokenExpiry =
    new Date(tokenExpiryLocalStorage).getTime() > new Date().getTime();
  if (checkTokenExpiry === false) {
    return {
      type: GENERIC.SET_SESSION_EXPIRE_STATE,
      payload: false,
      key
    };
  } else {
    return {
      type: actionType,
      isLoading: true,
      key
    };
  }
}

export function success(actionType, response, info, key) {
  return {
    type: actionType,
    payload: response,
    isSuccess: true,
    info: info,
    key,
  };
}

export function failure(actionType, error, key) {
  return {
    type: actionType,
    isSuccess: false,
    payload: error,
    info: error,
    key,
  };
}
export const MAIN_DASHBOARD = {
  GET_STATS_REQUEST: "GET_STATS_REQUEST",
  GET_STATS_SUCCESS: "GET_STATS_SUCCESS",
  GET_STATS_FAILURE: "GET_STATS_FAILURE",

  GET_SECTION_GRAPH_LISTING_REQUEST: "GET_SECTION_GRAPH_LISTING_REQUEST",
  GET_SECTION_GRAPH_LISTING_SUCCESS: "GET_SECTION_GRAPH_LISTING_SUCCESS",
  GET_SECTION_GRAPH_LISTING_FAILURE: "GET_SECTION_GRAPH_LISTING_FAILURE",


  GET_VISIT_SUMMARY_STATS_REQUEST: "GET_VISIT_SUMMARY_STATS_REQUEST",
  GET_VISIT_SUMMARY_STATS_SUCCESS: "GET_VISIT_SUMMARY_STATS_SUCCESS",
  GET_VISIT_SUMMARY_STATS_FAILURE: "GET_VISIT_SUMMARY_STATS_FAILURE",

  GET_MODERN_BIRTH_SPACING_STATS_REQUEST: "GET_MODERN_BIRTH_SPACING_STATS_REQUEST",
  GET_MODERN_BIRTH_SPACING_STATS_SUCCESS: "GET_MODERN_BIRTH_SPACING_STATS_SUCCESS",
  GET_MODERN_BIRTH_SPACING_STATS_FAILURE: "GET_MODERN_BIRTH_SPACING_STATS_FAILURE",

  GET_ANEMIAAND_TREATMENT_SUMMARY_STATS_REQUEST: "GET_ANEMIAAND_TREATMENT_SUMMARY_STATS_REQUEST",
  GET_ANEMIAAND_TREATMENT_SUMMARY_STATS_SUCCESS: "GET_ANEMIAAND_TREATMENT_SUMMARY_STATS_SUCCESS",
  GET_ANEMIAAND_TREATMENT_SUMMARY_STATS_FAILURE: "GET_ANEMIAAND_TREATMENT_SUMMARY_STATS_FAILURE",

  GET_DIARRHOEA_SUMMARY_STATS_REQUEST: "GET_DIARRHOEA_SUMMARY_STATS_REQUEST",
  GET_DIARRHOEA_SUMMARY_STATS_SUCCESS: "GET_DIARRHOEA_SUMMARY_STATS_SUCCESS",
  GET_DIARRHOEA_SUMMARY_STATS_FAILURE: "GET_DIARRHOEA_SUMMARY_STATS_FAILURE",

  GET_MALARIA_SUMMARY_STATS_REQUEST: "GET_MALARIA_SUMMARY_STATS_REQUEST",
  GET_MALARIA_SUMMARY_STATS_SUCCESS: "GET_MALARIA_SUMMARY_STATS_SUCCESS",
  GET_MALARIA_SUMMARY_STATS_FAILURE: "GET_MALARIA_SUMMARY_STATS_FAILURE",

  GET_PLASMODIUAM_SPECIES_SUMMARY_STATS_REQUEST: "GET_PLASMODIUAM_SPECIES_SUMMARY_STATS_REQUEST",
  GET_PLASMODIUAM_SPECIES_SUMMARY_STATS_SUCCESS: "GET_PLASMODIUAM_SPECIES_SUMMARY_STATS_SUCCESS",
  GET_PLASMODIUAM_SPECIES_SUMMARY_STATS_FAILURE: "GET_PLASMODIUAM_SPECIES_SUMMARY_STATS_FAILURE",

  GET_RAPE_AND_GBV_CASES_SUMMARY_STATS_REQUEST: "GET_RAPE_AND_GBV_CASES_SUMMARY_STATS_REQUEST",
  GET_RAPE_AND_GBV_CASES_SUMMARY_STATS_SUCCESS: "GET_RAPE_AND_GBV_CASES_SUMMARY_STATS_SUCCESS",
  GET_RAPE_AND_GBV_CASES_SUMMARY_STATS_FAILURE: "GET_RAPE_AND_GBV_CASES_SUMMARY_STATS_FAILURE",

  GET_UNDER_FIVE_MALARIA_SUMMARY_STATS_REQUEST: "GET_UNDER_FIVE_MALARIA_SUMMARY_STATS_REQUEST",
  GET_UNDER_FIVE_MALARIA_SUMMARY_STATS_SUCCESS: "GET_UNDER_FIVE_MALARIA_SUMMARY_STATS_SUCCESS",
  GET_UNDER_FIVE_MALARIA_SUMMARY_STATS_FAILURE: "GET_UNDER_FIVE_MALARIA_SUMMARY_STATS_FAILURE",

  GET_LDM_IMMUNIZATION_REQUEST: "GET_LDM_IMMUNIZATION_REQUEST",
  GET_LDM_IMMUNIZATION_SUCCESS: "GET_LDM_IMMUNIZATION_SUCCESS",
  GET_LDM_IMMUNIZATION_FAILURE: "GET_LDM_IMMUNIZATION_FAILURE",

  GET_LDM_MOTHER_DISCHARGE_REQUEST: "GET_LDM_MOTHER_DISCHARGE_REQUEST",
  GET_LDM_MOTHER_DISCHARGE_SUCCESS: "GET_LDM_MOTHER_DISCHARGE_SUCCESS",
  GET_LDM_MOTHER_DISCHARGE_FAILURE: "GET_LDM_MOTHER_DISCHARGE_FAILURE",

  GET_LDM_BABY_DISCHARGE_REQUEST: "GET_LDM_BABY_DISCHARGE_REQUEST",
  GET_LDM_BABY_DISCHARGE_SUCCESS: "GET_LDM_BABY_DISCHARGE_SUCCESS",
  GET_LDM_BABY_DISCHARGE_FAILURE: "GET_LDM_BABY_DISCHARGE_FAILURE",

  GET_LDM_PMTCT_SUMMARY_REQUEST: "GET_LDM_PMTCT_SUMMARY_REQUEST",
  GET_LDM_PMTCT_SUMMARY_SUCCESS: "GET_LDM_PMTCT_SUMMARY_SUCCESS",
  GET_LDM_PMTCT_SUMMARY_FAILURE: "GET_LDM_PMTCT_SUMMARY_FAILURE",

  GET_LDM_ART_PROPHYLAXIS_REQUEST: "GET_LDM_ART_PROPHYLAXIS_REQUEST",
  GET_LDM_ART_PROPHYLAXIS_SUCCESS: "GET_LDM_ART_PROPHYLAXIS_SUCCESS",
  GET_LDM_ART_PROPHYLAXIS_FAILURE: "GET_LDM_ART_PROPHYLAXIS_FAILURE",

  GET_LDM_REFERRAL_SUMMARY_REQUEST: "GET_LDM_REFERRAL_SUMMARY_REQUEST",
  GET_LDM_REFERRAL_SUMMARY_SUCCESS: "GET_LDM_REFERRAL_SUMMARY_SUCCESS",
  GET_LDM_REFERRAL_SUMMARY_FAILURE: "GET_LDM_REFERRAL_SUMMARY_FAILURE",

  GET_OUTCOME_SUMMARY_STATS_REQUEST: "GET_OUTCOME_SUMMARY_STATS_REQUEST",
  GET_OUTCOME_SUMMARY_STATS_SUCCESS: "GET_OUTCOME_SUMMARY_STATS_SUCCESS",
  GET_OUTCOME_SUMMARY_STATS_FAILURE: "GET_OUTCOME_SUMMARY_STATS_FAILURE",


  ///
  GET_HISTORY_GRAPH_REQUEST: "GET_HISTORY_GRAPH_REQUEST",
  GET_HISTORY_GRAPH_SUCCESS: "GET_HISTORY_GRAPH_SUCCESS",
  GET_HISTORY_GRAPH_FAILURE: "GET_HISTORY_GRAPH_FAILURE",

  GET_CASE_PROFILE_TABLE_REQUEST: "GET_CASE_PROFILE_TABLE_REQUEST",
  GET_CASE_PROFILE_TABLE_SUCCESS: "GET_CASE_PROFILE_TABLE_SUCCESS",
  GET_CASE_PROFILE_TABLE_FAILURE: "GET_CASE_PROFILE_TABLE_FAILURE",

  GET_BATCH_LIST_REQUEST: "GET_BATCH_LIST_REQUEST",
  GET_BATCH_LIST_SUCCESS: "GET_BATCH_LIST_SUCCESS",
  GET_BATCH_LIST_FAILURE: "GET_BATCH_LIST_FAILURE",

  ADD_BATCH_REQUEST: "ADD_BATCH_REQUEST",
  ADD_BATCH_SUCCESS: "ADD_BATCH_SUCCESS",
  ADD_BATCH_FAILURE: "ADD_BATCH_FAILURE",

  DELETE_BATCH_REQUEST: "DELETE_BATCH_REQUEST",
  DELETE_BATCH_SUCCESS: "DELETE_BATCH_SUCCESS",
  DELETE_BATCH_FAILURE: "DELETE_BATCH_FAILURE",
  
  GET_BATCH_REQUEST: "GET_BATCH_REQUEST",
  GET_BATCH_SUCCESS: "GET_BATCH_SUCCESS",
  GET_BATCH_FAILURE: "GET_BATCH_FAILURE",
  
  UPDATE_BATCH_REQUEST: "UPDATE_BATCH_REQUEST",
  UPDATE_BATCH_SUCCESS: "UPDATE_BATCH_SUCCESS",
  UPDATE_BATCH_FAILURE: "UPDATE_BATCH_FAILURE",
  

  GET_SMS_NUDGES_LIST_REQUEST: "GET_SMS_NUDGES_LIST_REQUEST",
  GET_SMS_NUDGES_LIST_SUCCESS: "GET_SMS_NUDGES_LIST_SUCCESS",
  GET_SMS_NUDGES_LIST_FAILURE: "GET_SMS_NUDGES_LIST_FAILURE",

  GET_IVR_LIST_REQUEST: "GET_IVR_LIST_REQUEST",
  GET_IVR_LIST_SUCCESS: "GET_IVR_LIST_SUCCESS",
  GET_IVR_LIST_FAILURE: "GET_IVR_LIST_FAILURE",

  GET_NOTIFY_VISITORS_LIST_REQUEST: "GET_NOTIFY_VISITORS_LIST_REQUEST",
  GET_NOTIFY_VISITORS_LIST_SUCCESS: "GET_NOTIFY_VISITORS_LIST_SUCCESS",
  GET_NOTIFY_VISITORS_LIST_FAILURE: "GET_NOTIFY_VISITORS_LIST_FAILURE",

  GET_ALL_COUNT_REQUEST: "GET_ALL_COUNT_REQUEST",
  GET_ALL_COUNT_SUCCESS: "GET_ALL_COUNT_SUCCESS",
  GET_ALL_COUNT_FAILURE: "GET_ALL_COUNT_FAILURE",

  EXPORT_HISTORY_GRAPH_DATA_REQUEST: "EXPORT_HISTORY_GRAPH_DATA_REQUEST",
  EXPORT_HISTORY_GRAPH_DATA_SUCCESS: "EXPORT_HISTORY_GRAPH_DATA_SUCCESS",
  EXPORT_HISTORY_GRAPH_DATA_FAILURE: "EXPORT_HISTORY_GRAPH_DATA_FAILURE",

  EXPORT_STATS_DATA_REQUEST: "EXPORT_STATS_DATA_REQUEST",
  EXPORT_STATS_DATA_SUCCESS: "EXPORT_STATS_DATA_SUCCESS",
  EXPORT_STATS_DATA_FAILURE: "EXPORT_STATS_DATA_FAILURE",

  EXPORT_NOTIFY_USER_DATA_REQUEST: "EXPORT_NOTIFY_USER_DATA_REQUEST",
  EXPORT_NOTIFY_USER_DATA_SUCCESS: "EXPORT_NOTIFY_USER_DATA_SUCCESS",
  EXPORT_NOTIFY_USER_DATA_FAILURE: "EXPORT_NOTIFY_USER_DATA_FAILURE",

  EXPORT_IVR_DATA_REQUEST: "EXPORT_IVR_DATA_REQUEST",
  EXPORT_IVR_DATA_SUCCESS: "EXPORT_IVR_DATA_SUCCESS",
  EXPORT_IVR_DATA_FAILURE: "EXPORT_IVR_DATA_FAILURE",

  SEND_NOTIFIED_USER_MANUAL_MESSAGE_REQUEST:
    " SEND_NOTIFIED_USER_MANUAL_MESSAGE_REQUEST",
  SEND_NOTIFIED_USER_MANUAL_MESSAGE_SUCCESS:
    " SEND_NOTIFIED_USER_MANUAL_MESSAGE_SUCCESS",
  SEND_NOTIFIED_USER_MANUAL_MESSAGE_FAILURE:
    " SEND_NOTIFIED_USER_MANUAL_MESSAGE_FAILURE",
};

export const IMMUNIZATION_DASHBOARD = {
  GET_IMZ_STATS_REQUEST: "GET_IMZ_STATS_REQUEST",
  GET_IMZ_STATS_SUCCESS: "GET_IMZ_STATS_SUCCESS",
  GET_IMZ_STATS_FAILURE: "GET_IMZ_STATS_FAILURE",

  GET_IMMUNIZATION_CHART_REQUEST: "GET_IMMUNIZATION_CHART_REQUEST",
  GET_IMMUNIZATION_CHART_SUCCESS: "GET_IMMUNIZATION_CHART_SUCCESS",
  GET_IMMUNIZATION_CHART_FAILURE: "GET_IMMUNIZATION_CHART_FAILURE",

  GET_COMPLETED_IMMUNIZATION_REQUEST: "GET_COMPLETED_IMMUNIZATION_REQUEST",
  GET_COMPLETED_IMMUNIZATION_SUCCESS: "GET_COMPLETED_IMMUNIZATION_SUCCESS",
  GET_COMPLETED_IMMUNIZATION_FAILURE: "GET_COMPLETED_IMMUNIZATION_FAILURE",

  GET_INCOMPLETED_IMMUNIZATION_REQUEST: "GET_INCOMPLETED_IMMUNIZATION_REQUEST",
  GET_INCOMPLETED_IMMUNIZATION_SUCCESS: "GET_INCOMPLETED_IMMUNIZATION_SUCCESS",
  GET_INCOMPLETED_IMMUNIZATION_FAILURE: "GET_INCOMPLETED_IMMUNIZATION_FAILURE",

  GET_REFUSED_ANOTHER_REQUEST: "GET_REFUSED_ANOTHER_REQUEST",
  GET_REFUSED_ANOTHER_SUCCESS: "GET_REFUSED_ANOTHER_SUCCESS",
  GET_REFUSED_ANOTHER_FAILURE: "GET_REFUSED_ANOTHER_FAILURE",

  GET_REFUSED_ANOTHER_GRAPH_REQUEST: "GET_REFUSED_ANOTHER_GRAPH_REQUEST",
  GET_REFUSED_ANOTHER_GRAPH_SUCCESS: "GET_REFUSED_ANOTHER_GRAPH_SUCCESS",
  GET_REFUSED_ANOTHER_GRAPH_FAILURE: "GET_REFUSED_ANOTHER_GRAPH_FAILURE",

  GET_LOOKUP_FACILITY_REQUEST: "GET_LOOKUP_FACILITY_REQUEST",
  GET_LOOKUP_FACILITY_SUCCESS: "GET_LOOKUP_FACILITY_SUCCESS",
  GET_LOOKUP_FACILITY_FAILURE: "GET_LOOKUP_FACILITY_FAILURE",

  GET_MISSED_IMMUNIZATION_REQUEST: "GET_MISSED_IMMUNIZATION_REQUEST",
  GET_MISSED_IMMUNIZATION_SUCCESS: "GET_MISSED_IMMUNIZATION_SUCCESS",
  GET_MISSED_IMMUNIZATION_FAILURE: "GET_MISSED_IMMUNIZATION_FAILURE",

  GET_CHILD_IMMUNIZATION_REQUEST: "GET_CHILD_IMMUNIZATION_REQUEST",
  GET_CHILD_IMMUNIZATION_SUCCESS: "GET_CHILD_IMMUNIZATION_SUCCESS",
  GET_CHILD_IMMUNIZATION_FAILURE: "GET_CHILD_IMMUNIZATION_FAILURE",

  APPROVE_OR_DENY_IMMUNIZATION_REQUEST: "APPROVE_OR_DENY_IMMUNIZATION_REQUEST",
  APPROVE_OR_DENY_IMMUNIZATION_SUCCESS: "APPROVE_OR_DENY_IMMUNIZATION_SUCCESS",
  APPROVE_OR_DENY_IMMUNIZATION_FAILURE: "APPROVE_OR_DENY_IMMUNIZATION_FAILURE",

  BULK_APPROVE_OR_DENY_IMMUNIZATION_REQUEST: "BULK_APPROVE_OR_DENY_IMMUNIZATION_REQUEST",
  BULK_APPROVE_OR_DENY_IMMUNIZATION_SUCCESS: "BULK_APPROVE_OR_DENY_IMMUNIZATION_SUCCESS",
  BULK_APPROVE_OR_DENY_IMMUNIZATION_FAILURE: "BULK_APPROVE_OR_DENY_IMMUNIZATION_FAILURE",

  GET_DELETE_IMMUNIZATION_REQUESTS_REQUEST: "GET_DELETE_IMMUNIZATION_REQUESTS_REQUEST",
  GET_DELETE_IMMUNIZATION_REQUESTS_SUCCESS: "GET_DELETE_IMMUNIZATION_REQUESTS_SUCCESS",
  GET_DELETE_IMMUNIZATION_REQUESTS_FAILURE: "GET_DELETE_IMMUNIZATION_REQUESTS_FAILURE",

  GET_PATIENT_VACCINATION_REQUEST: "GET_PATIENT_VACCINATION_REQUEST",
  GET_PATIENT_VACCINATION_SUCCESS: "GET_PATIENT_VACCINATION_SUCCESS",
  GET_PATIENT_VACCINATION_FAILURE: "GET_PATIENT_VACCINATION_FAILURE",

  EXPORT_STATS_EXCEL_REQUEST: "EXPORT_STATS_EXCEL_REQUEST",
  EXPORT_STATS_EXCEL_SUCCESS: "EXPORT_STATS_EXCEL_SUCCESS",
  EXPORT_STATS_EXCEL_FAILURE: "EXPORT_STATS_EXCEL_FAILURE",

  EXPORT_MISSED_IMMUNIZATION_REQUEST: "EXPORT_MISSED_IMMUNIZATION_REQUEST",
  EXPORT_MISSED_IMMUNIZATION_SUCCESS: "EXPORT_MISSED_IMMUNIZATION_SUCCESS",
  EXPORT_MISSED_IMMUNIZATION_FAILURE: "EXPORT_MISSED_IMMUNIZATION_FAILURE",

  EXPORT_CHILD_IMMUNIZATION_REQUEST: "EXPORT_CHILD_IMMUNIZATION_REQUEST",
  EXPORT_CHILD_IMMUNIZATION_SUCCESS: "EXPORT_CHILD_IMMUNIZATION_SUCCESS",
  EXPORT_CHILD_IMMUNIZATION_FAILURE: "EXPORT_CHILD_IMMUNIZATION_FAILURE",

  EXPORT_PATIENT_VACCINATION_REQUEST: "EXPORT_PATIENT_VACCINATION_REQUEST",
  EXPORT_PATIENT_VACCINATION_SUCCESS: "EXPORT_PATIENT_VACCINATION_SUCCESS",
  EXPORT_PATIENT_VACCINATION_FAILURE: "EXPORT_PATIENT_VACCINATION_FAILURE",
};
export const ANC_DASHBOARD = {
  GET_ANC_STATS_REQUEST: "GET_ANC_STATS_REQUEST",
  GET_ANC_STATS_SUCCESS: "GET_ANC_STATS_SUCCESS",
  GET_ANC_STATS_FAILURE: "GET_ANC_STATS_FAILURE",

  GET_VACCINATION_CHART_REQUEST: "GET_VACCINATION_CHART_REQUEST",
  GET_VACCINATION_CHART_SUCCESS: "GET_VACCINATION_CHART_SUCCESS",
  GET_VACCINATION_CHART_FAILURE: "GET_VACCINATION_CHART_FAILURE",

  GET_COMPLETED_VACCINATION_REQUEST: "GET_COMPLETED_VACCINATION_REQUEST",
  GET_COMPLETED_VACCINATION_SUCCESS: "GET_COMPLETED_VACCINATION_SUCCESS",
  GET_COMPLETED_VACCINATION_FAILURE: "GET_COMPLETED_VACCINATION_FAILURE",

  GET_INCOMPLETED_VACCINATION_REQUEST: "GET_INCOMPLETED_VACCINATION_REQUEST",
  GET_INCOMPLETED_VACCINATION_SUCCESS: "GET_INCOMPLETED_VACCINATION_SUCCESS",
  GET_INCOMPLETED_VACCINATION_FAILURE: "GET_INCOMPLETED_VACCINATION_FAILURE",

  GET_MISSED_VACCINATION_REQUEST: "GET_MISSED_VACCINATION_REQUEST",
  GET_MISSED_VACCINATION_SUCCESS: "GET_MISSED_VACCINATION_SUCCESS",
  GET_MISSED_VACCINATION_FAILURE: "GET_MISSED_VACCINATION_FAILURE",

  GET_MOTHER_VACCINATION_REQUEST: "GET_MOTHER_VACCINATION_REQUEST",
  GET_MOTHER_VACCINATION_SUCCESS: "GET_MOTHER_VACCINATION_SUCCESS",
  GET_MOTHER_VACCINATION_FAILURE: "GET_MOTHER_VACCINATION_FAILURE",

  GET_MOTHER_ANC_DETAILS_REQUEST: "GET_MOTHER_ANC_DETAILS_REQUEST",
  GET_MOTHER_ANC_DETAILS_SUCCESS: "GET_MOTHER_ANC_DETAILS_SUCCESS",
  GET_MOTHER_ANC_DETAILS_FAILURE: "GET_MOTHER_ANC_DETAILS_FAILURE",

  EXPORT_ANC_STATS_EXCEL_REQUEST: "EXPORT_ANC_STATS_EXCEL_REQUEST",
  EXPORT_ANC_STATS_EXCEL_SUCCESS: "EXPORT_ANC_STATS_EXCEL_SUCCESS",
  EXPORT_ANC_STATS_EXCEL_FAILURE: "EXPORT_ANC_STATS_EXCEL_FAILURE",

  EXPORT_MISSED_VACCINATION_REQUEST: "EXPORT_MISSED_VACCINATION_REQUEST",
  EXPORT_MISSED_VACCINATION_SUCCESS: "EXPORT_MISSED_VACCINATION_SUCCESS",
  EXPORT_MISSED_VACCINATION_FAILURE: "EXPORT_MISSED_VACCINATION_FAILURE",

  EXPORT_MOTHER_VACCINATION_REQUEST: "EXPORT_MOTHER_VACCINATION_REQUEST",
  EXPORT_MOTHER_VACCINATION_SUCCESS: "EXPORT_MOTHER_VACCINATION_SUCCESS",
  EXPORT_MOTHER_VACCINATION_FAILURE: "EXPORT_MOTHER_VACCINATION_FAILURE",
};
export const CLINICS_REQUEST = {
  GET_CLINICS_LIST_REQUEST: "GET_CLINICS_LIST_REQUEST",
  GET_CLINICS_LIST_SUCCESS: "GET_CLINICS_LIST_SUCCESS",
  GET_CLINICS_LIST_FAILURE: "GET_CLINICS_LIST_FAILURE",

  GET_TAGS_LIST_REQUEST: "GET_TAGS_LIST_REQUEST",
  GET_TAGS_LIST_SUCCESS: "GET_TAGS_LIST_SUCCESS",
  GET_TAGS_LIST_FAILURE: "GET_TAGS_LIST_FAILURE",

  GET_DHIS_CLINICS: "GET_DHIS_CLINICS",

  ADD_NEW_CLINIC_REQUEST: "ADD_NEW_CLINIC_REQUEST",
  ADD_NEW_CLINIC_SUCCESS: "ADD_NEW_CLINIC_SUCCESS",
  ADD_NEW_CLINIC_FAILURE: "ADD_NEW_CLINIC_FAILURE",

  GET_SINGLE_CLINIC_DETAILS_REQUEST: "GET_SINGLE_CLINIC_DETAILS_REQUEST",
  GET_SINGLE_CLINIC_DETAILS_SUCCESS: "GET_SINGLE_CLINIC_DETAILS_SUCCESS",
  GET_SINGLE_CLINIC_DETAILS_FAILURE: "GET_SINGLE_CLINIC_DETAILS_FAILURE",

  GET_CLINICS_LIST_SELECT_REQUEST: "GET_CLINICS_LIST_SELECT_REQUEST",
  GET_CLINICS_LIST_SELECT_SUCCESS: "GET_CLINICS_LIST_SELECT_SUCCESS",
  GET_CLINICS_LIST_SELECT_FAILURE: "GET_CLINICS_LIST_SELECT_FAILURE",

  ADMIN_TALLY_VALIDATION_REQUEST: "ADMIN_TALLY_VALIDATION_REQUEST",
  ADMIN_TALLY_VALIDATION_SUCCESS: "ADMIN_TALLY_VALIDATION_SUCCESS",
  ADMIN_TALLY_VALIDATION_FAILURE: "ADMIN_TALLY_VALIDATION_FAILURE",

  GET_CLINICS_MAPPING_LIST_SELECT_REQUEST:
    "GET_CLINICS_MAPPING_LIST_SELECT_REQUEST",
  GET_CLINICS_MAPPING_LIST_SELECT_SUCCESS:
    "GET_CLINICS_MAPPING_LIST_SELECT_SUCCESS",
  GET_CLINICS_MAPPING_LIST_SELECT_FAILURE:
    "GET_CLINICS_MAPPING_LIST_SELECT_FAILURE",
};
export const PERMISSIONS_REQUEST = {
  GET_ALL_POLICY_GROUPS_REQUEST: "GET_ALL_POLICY_GROUPS_REQUEST",
  GET_ALL_POLICY_GROUPS_SUCCESS: "GET_ALL_POLICY_GROUPS_SUCCESS",
  GET_ALL_POLICY_GROUPS_FAILURE: "GET_ALL_POLICY_GROUPS_FAILURE",

  GET_ALL_DASHBOARD_DETAILS_REQUEST: "GET_ALL_DASHBOARD_DETAILS_REQUEST",
  GET_ALL_DASHBOARD_DETAILS_SUCCESS: "GET_ALL_DASHBOARD_DETAILS_SUCCESS",
  GET_ALL_DASHBOARD_DETAILS_FAILURE: "GET_ALL_DASHBOARD_DETAILS_FAILURE",

  GET_ALL_ROLES_REQUEST: "GET_ALL_ROLES_REQUEST",
  GET_ALL_ROLES_SUCCESS: "GET_ALL_ROLES_SUCCESS",
  GET_ALL_ROLES_FAILURE: "GET_ALL_ROLES_FAILURE",

  GET_ALL_POLICES_REQUEST: "GET_ALL_POLICES_REQUEST",
  GET_ALL_POLICES_SUCCESS: "GET_ALL_POLICES_SUCCESS",
  GET_ALL_POLICES_FAILURE: "GET_ALL_POLICES_FAILURE",

  ADD_ROLE_REQUEST: "ADD_ROLE_REQUEST",
  ADD_ROLE_SUCCESS: "ADD_ROLE_SUCCESS",
  ADD_ROLE_FAILURE: "ADD_ROLE_FAILURE",

  DELETE_ROLE_REQUEST: "DELETE_ROLE_REQUEST",
  DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
  DELETE_ROLE_FAILURE: "DELETE_ROLE_FAILURE",

  ASSIGN_POLICES_REQUEST: "ASSIGN_POLICES_REQUEST",
  ASSIGN_POLICES_SUCCESS: "ASSIGN_POLICES_SUCCESS",
  ASSIGN_POLICES_FAILURE: "ASSIGN_POLICES_FAILURE",

  GET_DHIS2_CREDENTIAS_REQUEST: "GET_DHIS2_CREDENTIAS_REQUEST",
  GET_DHIS2_CREDENTIAS_SUCCESS: "GET_DHIS2_CREDENTIAS_SUCCESS",
  GET_DHIS2_CREDENTIAS_FAILURE: "GET_DHIS2_CREDENTIAS_FAILURE",

  UPDATE_DHIS2_CREDENTIAS_REQUEST: "UPDATE_DHIS2_CREDENTIAS_REQUEST",
  UPDATE_DHIS2_CREDENTIAS_SUCCESS: "UPDATE_DHIS2_CREDENTIAS_SUCCESS",
  UPDATE_DHIS2_CREDENTIAS_FAILURE: "UPDATE_DHIS2_CREDENTIAS_FAILURE",

  GET_LOCATION_TYPE_FILTER_REQUEST: "GET_LOCATION_TYPE_FILTER_REQUEST",
  GET_LOCATION_TYPE_FILTER_SUCCESS: "GET_LOCATION_TYPE_FILTER_SUCCESS",
  GET_LOCATION_TYPE_FILTER_FAILURE: "GET_LOCATION_TYPE_FILTER_FAILURE",

  GET_ALL_POLICES_BY_ROLE_ID_REQUEST: "GET_ALL_POLICES_BY_ROLE_ID_REQUEST",
  GET_ALL_POLICES_BY_ROLE_ID_SUCCESS: "GET_ALL_POLICES_BY_ROLE_ID_SUCCESS",
  GET_ALL_POLICES_BY_ROLE_ID_FAILURE: "GET_ALL_POLICES_BY_ROLE_ID_FAILURE",

  GET_ALL_SIDEBAR_POLICES_BY_ROLE_ID_REQUEST:
    "GET_ALL_SIDEBAR_POLICES_BY_ROLE_ID_REQUEST",
  GET_ALL_SIDEBAR_POLICES_BY_ROLE_ID_SUCCESS:
    "GET_ALL_SIDEBAR_POLICES_BY_ROLE_ID_SUCCESS",
  GET_ALL_SIDEBAR_POLICES_BY_ROLE_ID_FAILURE:
    "GET_ALL_SIDEBAR_POLICES_BY_ROLE_ID_FAILURE",

  ADD_POLICY_GROUP_REQUEST: "ADD_POLICY_GROUP_REQUEST",
  ADD_POLICY_GROUP_SUCCESS: "ADD_POLICY_GROUP_SUCCESS",
  ADD_POLICY_GROUP_FAILURE: "ADD_POLICY_GROUP_FAILURE",
};

export const ADMIN_REQUEST = {
  GET_ADMIN_LIST_REQUEST: "GET_ADMIN_LIST_REQUEST",
  GET_ADMIN_LIST_SUCCESS: "GET_ADMIN_LIST_SUCCESS",
  GET_ADMIN_LIST_FAILURE: "GET_ADMIN_LIST_FAILURE",

  GET_SINGLE_ADMIN_DETAILS_REQUEST: "GET_SINGLE_ADMIN_DETAILS_REQUEST",
  GET_SINGLE_ADMIN_DETAILS_SUCCESS: "GET_SINGLE_ADMIN_DETAILS_SUCCESS",
  GET_SINGLE_ADMIN_DETAILS_FAILURE: "GET_SINGLE_ADMIN_DETAILS_FAILURE",

  SET_ADMIN_ACTIVE_REQUEST: "GET_ADMIN_ACTIVE_REQUEST",
  SET_ADMIN_ACTIVE_SUCCESS: "GET_ADMIN_ACTIVE_SUCCESS",
  SET_ADMIN_ACTIVE_FAILURE: "GET_ADMIN_ACTIVE_FAILURE",

  ADD_EDIT_ADMIN_REQUEST: "ADD_EDIT_ADMIN_REQUEST",
  ADD_EDIT_ADMIN_SUCCESS: "ADD_EDIT_ADMIN_SUCCESS",
  ADD_EDIT_ADMIN_FAILURE: "ADD_EDIT_ADMIN_FAILURE",

  GET_ADMIN_CLINIC_LIST_REQUEST: "GET_ADMIN_CLINIC_LIST_REQUEST",
  GET_ADMIN_CLINIC_LIST_SUCCESS: "GET_ADMIN_CLINIC_LIST_SUCCESS",
  GET_ADMIN_CLINIC_LIST_FAILURE: "GET_ADMIN_CLINIC_LIST_FAILURE",

  ASSIGN_ADMIN_CLINIC_REQUEST: "ASSIGN_ADMIN_CLINIC_REQUEST",
  ASSIGN_ADMIN_CLINIC_SUCCESS: "ASSIGN_ADMIN_CLINIC_SUCCESS",
  ASSIGN_ADMIN_CLINIC_FAILURE: "ASSIGN_ADMIN_CLINIC_FAILURE",
};

export const PATIENT_REQUEST = {
  GET_PATIENT_LIST_REQUEST: "GET_PATIENT_LIST_REQUEST",
  GET_PATIENT_LIST_SUCCESS: "GET_PATIENT_LIST_SUCCESS",
  GET_PATIENT_LIST_FAILURE: "GET_PATIENT_LIST_FAILURE",

  GET_OTP_PATIENT_LIST_REQUEST: "GET_OTP_PATIENT_LIST_REQUEST",
  GET_OTP_PATIENT_LIST_SUCCESS: "GET_OTP_PATIENT_LIST_SUCCESS",
  GET_OTP_PATIENT_LIST_FAILURE: "GET_OTP_PATIENT_LIST_FAILURE",

  IS_REMINDER_ENABLED_REQUEST: "IS_REMINDER_ENABLED_REQUEST",
  IS_REMINDER_ENABLED_SUCCESS: "IS_REMINDER_ENABLED_SUCCESS",
  IS_REMINDER_ENABLED_FAILURE: "IS_REMINDER_ENABLED_FAILURE",

  CHANGE_PATIENT_GENDER_REQUEST: "CHANGE_PATIENT_GENDER_REQUEST",
  CHANGE_PATIENT_GENDER_SUCCESS: "CHANGE_PATIENT_GENDER_SUCCESS",
  CHANGE_PATIENT_GENDER_FAILURE: "CHANGE_PATIENT_GENDER_FAILURE",

  GET_SINGLE_PATIENT_DETAIL_REQUEST: "GET_SINGLE_PATIENT_DETAIL_REQUEST",
  GET_SINGLE_PATIENT_DETAIL_SUCCESS: "GET_SINGLE_PATIENT_DETAIL_SUCCESS",
  GET_SINGLE_PATIENT_DETAIL_FAILURE: "GET_SINGLE_PATIENT_DETAIL_FAILURE",

  GET_OTP_PATIENT_DETAIL_REQUEST: "GET_OTP_PATIENT_DETAIL_REQUEST",
  GET_OTP_PATIENT_DETAIL_SUCCESS: "GET_OTP_PATIENT_DETAIL_SUCCESS",
  GET_OTP_PATIENT_DETAIL_FAILURE: "GET_OTP_PATIENT_DETAIL_FAILURE",

  GET_OTP_VISIT_LIST_REQUEST: "GET_OTP_VISIT_LIST_REQUEST",
  GET_OTP_VISIT_LIST_SUCCESS: "GET_OTP_VISIT_LIST_SUCCESS",
  GET_OTP_VISIT_LIST_FAILURE: "GET_OTP_VISIT_LIST_FAILURE",

  GET_PATIENT_SELECT_LIST_REQUEST: "GET_PATIENT_SELECT_LIST_REQUEST",
  GET_PATIENT_SELECT_LIST_SUCCESS: "GET_PATIENT_SELECT_LIST_SUCCESS",
  GET_PATIENT_SELECT_LIST_FAILURE: "GET_PATIENT_SELECT_LIST_FAILURE",

  GET_PATIENT_CHILDS_LIST_REQUEST: "GET_PATIENT_CHILDS_LIST_REQUEST",
  GET_PATIENT_CHILDS_LIST_SUCCESS: "GET_PATIENT_CHILDS_LIST_SUCCESS",
  GET_PATIENT_CHILDS_LIST_FAILURE: "GET_PATIENT_CHILDS_LIST_FAILURE",

  GET_MOTHERS_WITH_ANC_DATA_REQUEST: "GET_MOTHERS_WITH_ANC_DATA_REQUEST",
  GET_MOTHERS_WITH_ANC_DATA_SUCCESS: "GET_MOTHERS_WITH_ANC_DATA_SUCCESS",
  GET_MOTHERS_WITH_ANC_DATA_FAILURE: "GET_MOTHERS_WITH_ANC_DATA_FAILURE",
};

export const LEARNING_CENTRE = {
  GET_VIDEOS_LEARNING_CENTRE_REQUEST: "GET_VIDEOS_LEARNING_CENTRE_REQUEST",
  GET_VIDEOS_LEARNING_CENTRE_SUCCESS: "GET_VIDEOS_LEARNING_CENTRE_SUCCESS",
  GET_VIDEOS_LEARNING_CENTRE_FAILURE: "GET_VIDEOS_LEARNING_CENTRE_FAILURE",

  ADD_VIDEO_LEARNING_CENTRE_REQUEST: "ADD_VIDEO_LEARNING_CENTRE_REQUEST",
  ADD_VIDEO_LEARNING_CENTRE_SUCCESS: "ADD_VIDEO_LEARNING_CENTRE_SUCCESS",
  ADD_VIDEO_LEARNING_CENTRE_FAILURE: "ADD_VIDEO_LEARNING_CENTRE_FAILURE",

  DELETE_VIDEO_LEARNING_CENTRE_REQUEST: "DELETE_VIDEO_LEARNING_CENTRE_REQUEST",
  DELETE_VIDEO_LEARNING_CENTRE_SUCCESS: "DELETE_VIDEO_LEARNING_CENTRE_SUCCESS",
  DELETE_VIDEO_LEARNING_CENTRE_FAILURE: "DELETE_VIDEO_LEARNING_CENTRE_FAILURE",

  GET_LANGUAGE_LIST_REQUEST: "GET_LANGUAGE_LIST_REQUEST",
  GET_LANGUAGE_LIST_SUCCESS: "GET_LANGUAGE_LIST_SUCCESS",
  GET_LANGUAGE_LIST_FAILURE: "GET_LANGUAGE_LIST_FAILURE",
};

export const CARE_GIVER_REQUEST = {
  GET_CARE_GIVER_LIST_REQUEST: "GET_CARE_GIVER_LIST_REQUEST",
  GET_CARE_GIVER_LIST_SUCCESS: "GET_CARE_GIVER_LIST_SUCCESS",
  GET_CARE_GIVER_LIST_FAILURE: "GET_CARE_GIVER_LIST_FAILURE",

  GET_SINGLE_CARE_GIVER_DETAIL_REQUEST: "GET_SINGLE_CARE_GIVER_DETAIL_REQUEST",
  GET_SINGLE_CARE_GIVER_DETAIL_SUCCESS: "GET_SINGLE_CARE_GIVER_DETAIL_SUCCESS",
  GET_SINGLE_CARE_GIVER_DETAIL_FAILURE: "GET_SINGLE_CARE_GIVER_DETAIL_FAILURE",

  ADD_CARE_GIVER_REQUEST: "ADD_CARE_GIVER_REQUEST",
  ADD_CARE_GIVER_SUCCESS: "ADD_CARE_GIVER_SUCCESS",
  ADD_CARE_GIVER_FAILURE: "ADD_CARE_GIVER_FAILURE",

  UPDATE_CARE_GIVER_REQUEST: "UPDATE_CARE_GIVER_REQUEST",
  UPDATE_CARE_GIVER_SUCCESS: "UPDATE_CARE_GIVER_SUCCESS",
  UPDATE_CARE_GIVER_FAILURE: "UPDATE_CARE_GIVER_FAILURE",

  GET_DOCTOR_CARE_GIVER_REQUEST: "GET_DOCTOR_CARE_GIVER_REQUEST",
  GET_DOCTOR_CARE_GIVER_SUCCESS: "GET_DOCTOR_CARE_GIVER_SUCCESS",
  GET_DOCTOR_CARE_GIVER_FAILURE: "GET_DOCTOR_CARE_GIVER_FAILURE",
};

export const CHILD_LIST = {
  GET_CHILDS_LIST_REQUEST: "GET_CHILDS_LIST_REQUEST",
  GET_CHILDS_LIST_SUCCESS: "GET_CHILDS_LIST_SUCCESS",
  GET_CHILDS_LIST_FAILURE: "GET_CHILDS_LIST_FAILURE",

  GET_SINGLE_CHILD_REQUEST: "GET_SINGLE_CHILD_REQUEST",
  GET_SINGLE_CHILD_SUCCESS: "GET_SINGLE_CHILD_SUCCESS",
  GET_SINGLE_CHILD_FAILURE: "GET_SINGLE_CHILD_FAILURE",

  GET_SINGLE_CHILD_EDIT_REQUEST: "GET_SINGLE_CHILD_EDIT_REQUEST",
  GET_SINGLE_CHILD_EDIT_SUCCESS: "GET_SINGLE_CHILD_EDIT_SUCCESS",
  GET_SINGLE_CHILD_EDIT_FAILURE: "GET_SINGLE_CHILD_EDIT_FAILURE",

  UPDATE_SINGLE_CHILD_REQUEST: "UPDATE_SINGLE_CHILD_REQUEST",
  UPDATE_SINGLE_CHILD_SUCCESS: "UPDATE_SINGLE_CHILD_SUCCESS",
  UPDATE_SINGLE_CHILD_FAILURE: "UPDATE_SINGLE_CHILD_FAILURE",
};

export const DOCTOR_REQUEST = {
  GET_DOCTOR_LIST_REQUEST: "GET_DOCTOR_LIST_REQUEST",
  GET_DOCTOR_LIST_SUCCESS: "GET_DOCTOR_LIST_SUCCESS",
  GET_DOCTOR_LIST_FAILURE: "GET_DOCTOR_LIST_FAILURE",

  GET_SINGLE_DOCTOR_DETAILS_REQUEST: "GET_SINGLE_DOCTOR_DETAILS_REQUEST",
  GET_SINGLE_DOCTOR_DETAILS_SUCCESS: "GET_SINGLE_DOCTOR_DETAILS_SUCCESS",
  GET_SINGLE_DOCTOR_DETAILS_FAILURE: "GET_SINGLE_DOCTOR_DETAILS_FAILURE",

  ADD_EDIT_DOCTOR_REQUEST: "ADD_EDIT_DOCTOR_REQUEST",
  ADD_EDIT_DOCTOR_SUCCESS: "ADD_EDIT_DOCTOR_SUCCESS",
  ADD_EDIT_DOCTOR_FAILURE: "ADD_EDIT_DOCTOR_FAILURE",
  RESET_ADD_EDIT_DOCTOR_ERROR: "RESET_ADD_EDIT_DOCTOR_ERROR",

  SET_DOCTOR_ACTIVE_REQUEST: "GET_DOCTOR_ACTIVE_REQUEST",
  SET_DOCTOR_ACTIVE_SUCCESS: "GET_DOCTOR_ACTIVE_SUCCESS",
  SET_DOCTOR_ACTIVE_FAILURE: "GET_DOCTOR_ACTIVE_FAILURE",

  GET_DOCTORS_SELECT_REQUEST: "GET_DOCTORS_SELECT_REQUEST",
  GET_DOCTORS_SELECT_SUCCESS: "GET_DOCTORS_SELECT_SUCCESS",
  GET_DOCTORS_SELECT_FAILURE: "GET_DOCTORS_SELECT_FAILURE",
};

export const GENERIC = {
  ACTIVATE_USER_REQUEST: "ACTIVATE_USER_REQUEST",
  ACTIVATE_USER_SUCCESS: "ACTIVATE_USER_SUCCESS",
  ACTIVATE_USER_FAILURE: "ACTIVATE_USER_FAILURE",

  DEACTIVATE_USER_REQUEST: "DEACTIVATE_USER_REQUEST",
  DEACTIVATE_USER_SUCCESS: "DEACTIVATE_USER_SUCCESS",
  DEACTIVATE_USER_FAILURE: "DEACTIVATE_USER_FAILURE",

  GET_USERS_LIST_EXCEPT_PATIENT_REQUEST:
    "GET_USERS_LIST_EXCEPT_PATIENT_REQUEST",
  GET_USERS_LIST_EXCEPT_PATIENT_SUCCESS:
    "GET_USERS_LIST_EXCEPT_PATIENT_SUCCESS",
  GET_USERS_LIST_EXCEPT_PATIENT_FAILURE:
    "GET_USERS_LIST_EXCEPT_PATIENT_FAILURE",

  SET_SESSION_EXPIRE_STATE: "SET_SESSION_EXPIRE_STATE",
};

export const SCHEDULE_REQUEST = {
  // GET_SCHEDULE_LIST_REQUEST: "GET_SCHEDULE_LIST_REQUEST",
  // GET_SCHEDULE_LIST_SUCCESS: "GET_SCHEDULE_LIST_SUCCESS",
  // GET_SCHEDULE_LIST_FAILURE: "GET_SCHEDULE_LIST_FAILURE",

  GET_DOCTOR_SCHEDULE_LIST_REQUEST: "GET_DOCTOR_SCHEDULE_LIST_REQUEST",
  GET_DOCTOR_SCHEDULE_LIST_SUCCESS: "GET_DOCTOR_SCHEDULE_LIST_SUCCESS",
  GET_DOCTOR_SCHEDULE_LIST_FAILURE: "GET_DOCTOR_SCHEDULE_LIST_FAILURE",

  GET_SINGLE_SCHEDULE_DETAILS_REQUEST: "GET_SINGLE_SCHEDULE_DETAILS_REQUEST",
  GET_SINGLE_SCHEDULE_DETAILS_SUCCESS: "GET_SINGLE_SCHEDULE_DETAILS_SUCCESS",
  GET_SINGLE_SCHEDULE_DETAILS_FAILURE: "GET_SINGLE_SCHEDULE_DETAILS_FAILURE",

  ADD_SCHEDULE_REQUEST: "ADD_SCHEDULE_REQUEST",
  ADD_SCHEDULE_SUCCESS: "ADD_SCHEDULE_SUCCESS",
  ADD_SCHEDULE_FAILURE: "ADD_SCHEDULE_FAILURE",

  EDIT_SCHEDULE_REQUEST: "EDIT_SCHEDULE_REQUEST",
  EDIT_SCHEDULE_SUCCESS: "EDIT_SCHEDULE_SUCCESS",
  EDIT_SCHEDULE_FAILURE: "EDIT_SCHEDULE_FAILURE",
};

export const LOGGING_ACTIVITY_REQUEST = {
  GET_LOGGING_ACTIVITY_LIST_REQUEST: "GET_LOGGING_ACTIVITY_LIST_REQUEST",
  GET_LOGGING_ACTIVITY_LIST_SUCCESS: "GET_LOGGING_ACTIVITY_LIST_SUCCESS",
  GET_LOGGING_ACTIVITY_LIST_FAILURE: "GET_LOGGING_ACTIVITY_LIST_FAILURE",

  LOGIN_WITH_2FA_REQUEST: "LOGIN_WITH_2FA_REQUEST",
  LOGIN_WITH_2FA_SUCCESS: "LOGIN_WITH_2FA_SUCCESS",
  LOGIN_WITH_2FA_FAILURE: "LOGIN_WITH_2FA_FAILURE",

  GET_LOGGING_ACTIVITY_ANALYTICS_REQUEST:
    "GET_LOGGING_ACTIVITY_ANALYTICS_REQUEST",
  GET_LOGGING_ACTIVITY_ANALYTICS_SUCCESS:
    "GET_LOGGING_ACTIVITY_ANALYTICS_SUCCESS",
  GET_LOGGING_ACTIVITY_ANALYTICS_FAILURE:
    "GET_LOGGING_ACTIVITY_ANALYTICS_FAILURE",

  GET_ALL_TALLY_VALIDATION_REQUEST: "GET_ALL_TALLY_VALIDATION_REQUEST",
  GET_ALL_TALLY_VALIDATION_SUCCESS: "GET_ALL_TALLY_VALIDATION_SUCCESS",
  GET_ALL_TALLY_VALIDATION_FAILURE: "GET_ALL_TALLY_VALIDATION_FAILURE",

  GET_LOGGING_ACTIVITY_ANALYTICS_CHART_REQUEST:
    "GET_LOGGING_ACTIVITY_ANALYTICS_CHART_REQUEST",
  GET_LOGGING_ACTIVITY_ANALYTICS_CHART_SUCCESS:
    "GET_LOGGING_ACTIVITY_ANALYTICS_CHART_SUCCESS",
  GET_LOGGING_ACTIVITY_ANALYTICS_CHART_FAILURE:
    "GET_LOGGING_ACTIVITY_ANALYTICS_CHART_FAILURE",

  GET_LOGGING_ACTIVITY_ANALYTICS_EXPORT_REQUEST:
    "GET_LOGGING_ACTIVITY_ANALYTICS_EXPORT_REQUEST",
  GET_LOGGING_ACTIVITY_ANALYTICS_EXPORT_SUCCESS:
    "GET_LOGGING_ACTIVITY_ANALYTICS_EXPORT_SUCCESS",
  GET_LOGGING_ACTIVITY_ANALYTICS_EXPORT_FAILURE:
    "GET_LOGGING_ACTIVITY_ANALYTICS_EXPORT_FAILURE",
};

export const CHILD_IMMUNIZATION_REQUEST = {
  GET_PENDING_VACCINE_LIST_REQUEST: "GET_PENDING_VACCINE_LIST_REQUEST",
  GET_PENDING_VACCINE_LIST_SUCCESS: "GET_PENDING_VACCINE_LIST_SUCCESS",
  GET_PENDING_VACCINE_LIST_FAILURE: "GET_PENDING_VACCINE_LIST_FAILURE",

  ADD_IMMUNIZATION_REQUEST: "ADD_IMMUNIZATION_REQUEST",
  ADD_IMMUNIZATION_SUCCESS: "ADD_IMMUNIZATION_SUCCESS",
  ADD_IMMUNIZATION_FAILURE: "ADD_IMMUNIZATION_FAILURE",

  UPDATE_IMMUNIZATION_REQUEST: "UPDATE_IMMUNIZATION_REQUEST",
  UPDATE_IMMUNIZATION_SUCCESS: "UPDATE_IMMUNIZATION_SUCCESS",
  UPDATE_IMMUNIZATION_FAILURE: "UPDATE_IMMUNIZATION_FAILURE",

  GET_IMMUNIZATION_LIST_REQUEST: "GET_IMMUNIZATION_LIST_REQUEST",
  GET_IMMUNIZATION_LIST_SUCCESS: "GET_IMMUNIZATION_LIST_SUCCESS",
  GET_IMMUNIZATION_LIST_FAILURE: "GET_IMMUNIZATION_LIST_FAILURE",

  GET_SINGLE_IMMUNIZATION_DATAILS_REQUEST:
    "GET_SINGLE_IMMUNIZATION_DATAILS_REQUEST",
  GET_SINGLE_IMMUNIZATION_DATAILS_SUCCESS:
    "GET_SINGLE_IMMUNIZATION_DATAILS_SUCCESS",
  GET_SINGLE_IMMUNIZATION_DATAILS_FAILURE:
    "GET_SINGLE_IMMUNIZATION_DATAILS_FAILURE",
};
export const CHILD_OBSERVATION_REQUEST = {
  ADD_CHILD_OBSERVATION_REQUEST: "ADD_CHILD_OBSERVATION_REQUEST",
  ADD_CHILD_OBSERVATION_SUCCESS: "ADD_CHILD_OBSERVATION_SUCCESS",
  ADD_CHILD_OBSERVATION_FAILURE: "ADD_CHILD_OBSERVATION_FAILURE",

  UPDATE_CHILD_OBSERVATION_REQUEST: "UPDATE_CHILD_OBSERVATION_REQUEST",
  UPDATE_CHILD_OBSERVATION_SUCCESS: "UPDATE_CHILD_OBSERVATION_SUCCESS",
  UPDATE_CHILD_OBSERVATION_FAILURE: "UPDATE_CHILD_OBSERVATION_FAILURE",

  GET_CHILD_OBSERVATION_REQUEST: "GET_CHILD_OBSERVATION_REQUEST",
  GET_CHILD_OBSERVATION_SUCCESS: "GET_CHILD_OBSERVATION_SUCCESS",
  GET_CHILD_OBSERVATION_FAILURE: "GET_CHILD_OBSERVATION_FAILURE",

  GET_SINGLE_CHILD_OBSERVATION_DETAIL_REQUEST:
    "GET_SINGLE_CHILD_OBSERVATION_DETAIL_REQUEST",
  GET_SINGLE_CHILD_OBSERVATION_DETAIL_SUCCESS:
    "GET_SINGLE_CHILD_OBSERVATION_DETAIL_SUCCESS",
  GET_SINGLE_CHILD_OBSERVATION_DETAIL_FAILURE:
    "GET_SINGLE_CHILD_OBSERVATION_DETAIL_FAILURE",
};
export const CHILD_PRESCRIPTION_REQUEST = {
  ADD_CHILD_PRESCRIPTION_REQUEST: "ADD_CHILD_PRESCRIPTION_REQUEST",
  ADD_CHILD_PRESCRIPTION_SUCCESS: "ADD_CHILD_PRESCRIPTION_SUCCESS",
  ADD_CHILD_PRESCRIPTION_FAILURE: "ADD_CHILD_PRESCRIPTION_FAILURE",

  UPDATE_CHILD_PRESCRIPTION_REQUEST: "UPDATE_CHILD_PRESCRIPTION_REQUEST",
  UPDATE_CHILD_PRESCRIPTION_SUCCESS: "UPDATE_CHILD_PRESCRIPTION_SUCCESS",
  UPDATE_CHILD_PRESCRIPTION_FAILURE: "UPDATE_CHILD_PRESCRIPTION_FAILURE",

  GET_CHILD_PRESCRIPTION_REQUEST: "GET_CHILD_PRESCRIPTION_REQUEST",
  GET_CHILD_PRESCRIPTION_SUCCESS: "GET_CHILD_PRESCRIPTION_SUCCESS",
  GET_CHILD_PRESCRIPTION_FAILURE: "GET_CHILD_PRESCRIPTION_FAILURE",

  GET_SINGLE_CHILD_PRESCRIPTION_DETAIL_REQUEST:
    "GET_SINGLE_CHILD_PRESCRIPTION_DETAIL_REQUEST",
  GET_SINGLE_CHILD_PRESCRIPTION_DETAIL_SUCCESS:
    "GET_SINGLE_CHILD_PRESCRIPTION_DETAIL_SUCCESS",
  GET_SINGLE_CHILD_PRESCRIPTION_DETAIL_FAILURE:
    "GET_SINGLE_CHILD_PRESCRIPTION_DETAIL_FAILURE",
};

export const DHIS2_DATASET_CONFIGURATION_REQUEST = {
  ADD_DATASET_CONFIGURATION_REQUEST: "ADD_DATASET_CONFIGURATION_REQUEST",
  ADD_DATASET_CONFIGURATION_SUCCESS: "ADD_DATASET_CONFIGURATION_SUCCESS",
  ADD_DATASET_CONFIGURATION_FAILURE: "ADD_DATASET_CONFIGURATION_FAILURE",

  UPDATE_DATASET_CONFIGURATION_REQUEST: "UPDATE_DATASET_CONFIGURATION_REQUEST",
  UPDATE_DATASET_CONFIGURATION_SUCCESS: "UPDATE_DATASET_CONFIGURATION_SUCCESS",
  UPDATE_DATASET_CONFIGURATION_FAILURE: "UPDATE_DATASET_CONFIGURATION_FAILURE",

  DELETE_DATASET_CONFIGURATION_REQUEST: "DELETE_DATASET_CONFIGURATION_REQUEST",
  DELETE_DATASET_CONFIGURATION_SUCCESS: "DELETE_DATASET_CONFIGURATION_SUCCESS",
  DELETE_DATASET_CONFIGURATION_FAILURE: "DELETE_DATASET_CONFIGURATION_FAILURE",

  GET_DATASET_CONFIGURATION_REQUEST: "GET_DATASET_CONFIGURATION_REQUEST",
  GET_DATASET_CONFIGURATION_SUCCESS: "GET_DATASET_CONFIGURATION_SUCCESS",
  GET_DATASET_CONFIGURATION_FAILURE: "GET_DATASET_CONFIGURATION_FAILURE",

  GET_DATASET_MAPPING_SELECT_LIST_REQUEST:
    "GET_DATASET_MAPPING_SELECT_LIST_REQUEST",
  GET_DATASET_MAPPING_SELECT_LIST_SUCCESS:
    "GET_DATASET_MAPPING_SELECT_LIST_SUCCESS",
  GET_DATASET_MAPPING_SELECT_LIST_FAILURE:
    "GET_DATASET_MAPPING_SELECT_LIST_FAILURE",
};

export const DHIS2_DATAELEMENT_CONFIGURATION_REQUEST = {
  ADD_DATAELEMENT_CONFIGURATION_REQUEST:
    "ADD_DATAELEMENT_CONFIGURATION_REQUEST",
  ADD_DATAELEMENT_CONFIGURATION_SUCCESS:
    "ADD_DATAELEMENT_CONFIGURATION_SUCCESS",
  ADD_DATAELEMENT_CONFIGURATION_FAILURE:
    "ADD_DATAELEMENT_CONFIGURATION_FAILURE",

  UPDATE_DATAELEMENT_CONFIGURATION_REQUEST:
    "UPDATE_DATAELEMENT_CONFIGURATION_REQUEST",
  UPDATE_DATAELEMENT_CONFIGURATION_SUCCESS:
    "UPDATE_DATAELEMENT_CONFIGURATION_SUCCESS",
  UPDATE_DATAELEMENT_CONFIGURATION_FAILURE:
    "UPDATE_DATAELEMENT_CONFIGURATION_FAILURE",

  DELETE_DATAELEMENT_CONFIGURATION_REQUEST:
    "DELETE_DATAELEMENT_CONFIGURATION_REQUEST",
  DELETE_DATAELEMENT_CONFIGURATION_SUCCESS:
    "DELETE_DATAELEMENT_CONFIGURATION_SUCCESS",
  DELETE_DATAELEMENT_CONFIGURATION_FAILURE:
    "DELETE_DATAELEMENT_CONFIGURATION_FAILURE",

  GET_DATAELEMENT_CONFIGURATION_REQUEST:
    "GET_DATAELEMENT_CONFIGURATION_REQUEST",
  GET_DATAELEMENT_CONFIGURATION_SUCCESS:
    "GET_DATAELEMENT_CONFIGURATION_SUCCESS",
  GET_DATAELEMENT_CONFIGURATION_FAILURE:
    "GET_DATAELEMENT_CONFIGURATION_FAILURE",

  GET_DATAELEMENT_MAPPING_SELECT_LIST_REQUEST:
    "GET_DATAELEMENT_MAPPING_SELECT_LIST_REQUEST",
  GET_DATAELEMENT_MAPPING_SELECT_LIST_SUCCESS:
    "GET_DATAELEMENT_MAPPING_SELECT_LIST_SUCCESS",
  GET_DATAELEMENT_MAPPING_SELECT_LIST_FAILURE:
    "GET_DATAELEMENT_MAPPING_SELECT_LIST_FAILURE",
};

export const DHIS2_DATASET_SETUP_REQUEST = {
  ADD_DATASET_SETUP_REQUEST: "ADD_DATASET_SETUP_REQUEST",
  ADD_DATASET_SETUP_SUCCESS: "ADD_DATASET_SETUP_SUCCESS",
  ADD_DATASET_SETUP_FAILURE: "ADD_DATASET_SETUP_FAILURE",

  UPDATE_DATASET_SETUP_REQUEST: "UPDATE_DATASET_SETUP_REQUEST",
  UPDATE_DATASET_SETUP_SUCCESS: "UPDATE_DATASET_SETUP_SUCCESS",
  UPDATE_DATASET_SETUP_FAILURE: "UPDATE_DATASET_SETUP_FAILURE",

  DELETE_DATASET_SETUP_REQUEST: "DELETE_DATASET_SETUP_REQUEST",
  DELETE_DATASET_SETUP_SUCCESS: "DELETE_DATASET_SETUP_SUCCESS",
  DELETE_DATASET_SETUP_FAILURE: "DELETE_DATASET_SETUP_FAILURE",

  GET_DATASET_SETUP_LIST_REQUEST: "GET_DATASET_SETUP_LIST_REQUEST",
  GET_DATASET_SETUP_LIST_SUCCESS: "GET_DATASET_SETUP_LIST_SUCCESS",
  GET_DATASET_SETUP_LIST_FAILURE: "GET_DATASET_SETUP_LIST_FAILURE",

  GET_DATABASE_LABEL_LIST_REQUEST: "GET_DATABASE_LABEL_LIST_REQUEST",
  GET_DATABASE_LABEL_LIST_SUCCESS: "GET_DATABASE_LABEL_LIST_SUCCESS",
  GET_DATABASE_LABEL_LIST_FAILURE: "GET_DATABASE_LABEL_LIST_FAILURE",

  CHECK_DHIS_PUSH_REQUEST: "CHECK_DHIS_PUSH_REQUEST",
  CHECK_DHIS_PUSH_SUCCESS: "CHECK_DHIS_PUSH_SUCCESS",
  CHECK_DHIS_PUSH_FAILURE: "CHECK_DHIS_PUSH_FAILURE",
};

export const DHIS2_FACILITY_CONFIGURATION_REQUEST = {
  GET_ALL_DHIS2_FACILITY_REQUEST: "GET_ALL_DHIS2_FACILITY_REQUEST",
  GET_ALL_DHIS2_FACILITY_SUCCESS: "GET_ALL_DHIS2_FACILITY_SUCCESS",
  GET_ALL_DHIS2_FACILITY_FAILURE: "GET_ALL_DHIS2_FACILITY_FAILURE",

  GET_BY_ID_DHIS2_FACILITY_REQUEST: "GET_BY_ID_DHIS2_FACILITY_REQUEST",
  GET_BY_ID_DHIS2_FACILITY_SUCCESS: "GET_BY_ID_DHIS2_FACILITY_SUCCESS",
  GET_BY_ID_DHIS2_FACILITY_FAILURE: "GET_BY_ID_DHIS2_FACILITY_FAILURE",

  UPDATE_DHIS2_FACILITY_REQUEST: "UPDATE_DHIS2_FACILITY_REQUEST",
  UPDATE_DHIS2_FACILITY_SUCCESS: "UPDATE_DHIS2_FACILITY_SUCCESS",
  UPDATE_DHIS2_FACILITY_FAILURE: "UPDATE_DHIS2_FACILITY_FAILURE",

  DELETE_DHIS2_FACILITY_REQUEST: "DELETE_DHIS2_FACILITY_REQUEST",
  DELETE_DHIS2_FACILITY_SUCCESS: "DELETE_DHIS2_FACILITY_SUCCESS",
  DELETE_DHIS2_FACILITY_FAILURE: "DELETE_DHIS2_FACILITY_FAILURE",

  ADD_DHIS2_FACILITY_REQUEST: "ADD_DHIS2_FACILITY_REQUEST",
  ADD_DHIS2_FACILITY_SUCCESS: "ADD_DHIS2_FACILITY_SUCCESS",
  ADD_DHIS2_FACILITY_FAILURE: "ADD_DHIS2_FACILITY_FAILURE",

  ADD_FACILITY_CONFIGURATION_REQUEST: "ADD_FACILITY_CONFIGURATION_REQUEST",
  ADD_FACILITY_CONFIGURATION_SUCCESS: "ADD_FACILITY_CONFIGURATION_SUCCESS",
  ADD_FACILITY_CONFIGURATION_FAILURE: "ADD_FACILITY_CONFIGURATION_FAILURE",

  UPDATE_FACILITY_CONFIGURATION_REQUEST:
    "UPDATE_FACILITY_CONFIGURATION_REQUEST",
  UPDATE_FACILITY_CONFIGURATION_SUCCESS:
    "UPDATE_FACILITY_CONFIGURATION_SUCCESS",
  UPDATE_FACILITY_CONFIGURATION_FAILURE:
    "UPDATE_FACILITY_CONFIGURATION_FAILURE",

  DELETE_FACILITY_CONFIGURATION_REQUEST:
    "DELETE_FACILITY_CONFIGURATION_REQUEST",
  DELETE_FACILITY_CONFIGURATION_SUCCESS:
    "DELETE_FACILITY_CONFIGURATION_SUCCESS",
  DELETE_FACILITY_CONFIGURATION_FAILURE:
    "DELETE_FACILITY_CONFIGURATION_FAILURE",

  GET_FACILITY_CONFIGURATION_MAPPING_LIST_REQUEST:
    "GET_FACILITY_CONFIGURATION_MAPPING_LIST_REQUEST",
  GET_FACILITY_CONFIGURATION_MAPPING_LIST_SUCCESS:
    "GET_FACILITY_CONFIGURATION_MAPPING_LIST_SUCCESS",
  GET_FACILITY_CONFIGURATION_MAPPING_LIST_FAILURE:
    "GET_FACILITY_CONFIGURATION_MAPPING_LIST_FAILURE",

  ADD_LOCAL_FACILITY_CONFIGURATION_DATA:
    "ADD_LOCAL_FACILITY_CONFIGURATION_DATA",

  REMOVE_LOCAL_FACILITY_CONFIGURATION_DATA:
    "REMOVE_LOCAL_FACILITY_CONFIGURATION_DATA",
};

export const DHIS2_DATA_UPLOAD_REQUEST = {
  GET_DATA_UPLOAD_REQUEST: "GET_DATA_UPLOAD_REQUEST",
  GET_DATA_UPLOAD_SUCCESS: "GET_DATA_UPLOAD_SUCCESS",
  GET_DATA_UPLOAD_FAILURE: "GET_DATA_UPLOAD_FAILURE",

  PUSH_DATA_UPLOAD_REQUEST: "PUSH_DATA_UPLOAD_REQUEST",
  PUSH_DATA_UPLOAD_SUCCESS: "PUSH_DATA_UPLOAD_SUCCESS",
  PUSH_DATA_UPLOAD_FAILURE: "PUSH_DATA_UPLOAD_FAILURE",

  GET_DHIS_LOGIN_REQUEST: "GET_DHIS_LOGIN_REQUEST",
  GET_DHIS_LOGIN_SUCCESS: "GET_DHIS_LOGIN_SUCCESS",
  GET_DHIS_LOGIN_FAILURE: "GET_DHIS_LOGIN_FAILURE",

  GET_DHIS_PUSH_REQUESTS_REQUEST: "GET_DHIS_PUSH_REQUESTS_REQUEST",
  GET_DHIS_PUSH_REQUESTS_SUCCESS: "GET_DHIS_PUSH_REQUESTS_SUCCESS",
  GET_DHIS_PUSH_REQUESTS_FAILURE: "GET_DHIS_PUSH_REQUESTS_FAILURE",

  GENRATE_DHIS_PUSH_REQUESTS_REQUEST: "GENRATE_DHIS_PUSH_REQUESTS_REQUEST",
  GENRATE_DHIS_PUSH_REQUESTS_SUCCESS: "GENRATE_DHIS_PUSH_REQUESTS_SUCCESS",
  GENRATE_DHIS_PUSH_REQUESTS_FAILURE: "GENRATE_DHIS_PUSH_REQUESTS_FAILURE",

  GET_DHIS_LOGS_REQUEST: "GET_DHIS_LOGS_REQUEST",
  GET_DHIS_LOGS_SUCCESS: "GET_DHIS_LOGS_SUCCESS",
  GET_DHIS_LOGS_FAILURE: "GET_DHIS_LOGS_FAILURE",
};

export const LOCATIONS = {
  GET_LOCATIONS_REQUEST: "GET_LOCATIONS_REQUEST",
  GET_LOCATIONS_SUCCESS: "GET_LOCATIONS_SUCCESS",
  GET_LOCATIONS_FAILURE: "GET_LOCATIONS_FAILURE",

  GET_ALL_LOCATIONS_REQUEST: "GET_ALL_LOCATIONS_REQUEST",
  GET_ALL_LOCATIONS_SUCCESS: "GET_ALL_LOCATIONS_SUCCESS",
  GET_ALL_LOCATIONS_FAILURE: "GET_ALL_LOCATIONS_FAILURE",

  ADD_LOCATIONS_REQUEST: "ADD_LOCATIONS_REQUEST",
  ADD_LOCATIONS_SUCCESS: "ADD_LOCATIONS_SUCCESS",
  ADD_LOCATIONS_FAILURE: "ADD_LOCATIONS_FAILURE",
};
